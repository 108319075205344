import React, { useState } from "react";
import styled from "styled-components";
import locales from "../../locales";
import { getUrlForLocale } from "../../utilities/i18n";
import downChevronImage from "../../assets/images/down-chevron-small.svg";
import { Link } from "gatsby";
import { fontR14m, fontR14r } from "../../styles/fonts";
import colors from "../../styles/colors";
import globeImage from "../../assets/images/globe.svg";
import { rgba } from "polished";
import routes from "../../routes";

const Selector = styled.div`
  ${fontR14r};
  color: ${colors.darkGrey};
  text-transform: uppercase;
  text-align: center;
  width: 19px;
`;

const GlobeImage = styled.img``;

const LanguageLink = styled(Link)`
  ${fontR14r};
  display: block;
  text-decoration: none;
  color: ${colors.darkGrey};
  padding: 10px 21px;
`;

const DropdownItem = styled.li`
  background: ${colors.white};
`;

const Dropdown = styled.ul`
  ${fontR14m};
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 3px 6px ${rgba(colors.black, 0.16)};
  z-index: 1;

  ${DropdownItem} {
    border-bottom: 1px solid ${colors.lightGrey};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const LanguageButton = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 61px;

  ${Dropdown} {
    position: absolute;
    top: 100%;
    transform: translateY(10px);
    right: 0;
  }

  ${LanguageButton} {
    margin-left: 5px;
    margin-top: -2px;
  }
  ${GlobeImage} {
    margin-right: 5px;
    margin-top: 2px;
  }
`;

const LanguageSelector = ({ url, locale, ...props }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [dropdownList, setDropdownList] = useState(locales);

  const handleDropdown = () => {
    const newDropdownList = [
      locales.find((curr) => curr.path === locale.path),
      ...locales.filter((curr) => curr.path !== locale.path),
    ];
    setDropdownList(newDropdownList);
    setDropdownActive(!dropdownActive);
  };

  const rawUrl = url.replace(`/${locale.path}/`, "/");

  const targetUrl = rawUrl.indexOf(routes.blog) !== -1 ? routes.blog : rawUrl;

  return (
    <Container
      {...props}
      onClick={() => {
        handleDropdown();
        // window.analytics.track("Clicked Language Selector");
      }}
    >
      <GlobeImage src={globeImage} alt="globe-image" />
      <Selector>{locale.path}</Selector>
      {dropdownActive && (
        <Dropdown>
          {dropdownList
            .filter((currLocale) => currLocale.path !== locale.path)
            .map((currLocale, index) => {
              return (
                <DropdownItem key={index} value={currLocale.path}>
                  <LanguageLink to={getUrlForLocale(targetUrl, currLocale)}>
                    {currLocale.path}
                  </LanguageLink>
                </DropdownItem>
              );
            })}
        </Dropdown>
      )}
      <LanguageButton>
        <img loading={"lazy"} src={downChevronImage} alt="down-chevron" />
      </LanguageButton>
    </Container>
  );
};

export default LanguageSelector;
