import React from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import { fontFG16m, fontFGr } from "../styles/fonts";
import { FormattedMessage } from "react-intl";
import { rgba } from "polished";

import colors from "../styles/colors";
import SmartLink from "./shared/SmartLink";
import shiftIcon from "../assets/icons/nav-icon-shift.svg";
import timeIcon from "../assets/icons/nav-icon-time.svg";
import analyticsIcon from "../assets/icons/nav-icon-analytics.svg";
import recruitmentIcon from "../assets/icons/nav-icon-recruitment.svg";
import staffingIcon from "../assets/icons/nav-icon-staffing.svg";
import enterpriseIcon from "../assets/icons/nav-icon-enterprise.svg";
import workforceManagementIcon from "../assets/icons/nav-icon-workforce-management.svg";

const FEATURE_ICONS = {
  "shift-scheduling-software": shiftIcon,
  "time-attendance-software": timeIcon,
  "workforce-analytics": analyticsIcon,
  recruitment: recruitmentIcon,
  staffing: staffingIcon,
  bpo: enterpriseIcon,
  "workforce-management-software": workforceManagementIcon,
};

const RightArrowIcon = styled(Icon)`
  font-size: 10px;
`;
const CustomIcon = styled.div`
  mask: url(${(props) => props.icon});
  mask-repeat: no-repeat;
  margin-right: 16px;
  width: 0;
  height: 24px;
  padding-right: 24px;
  background-color: ${colors.white};
  transition: all 300ms ease-out;
`;
const Parent = styled(SmartLink)`
  ${fontFG16m};
  color: ${colors.brandBlue};
  padding: 8px 0;
  position: relative;
`;
const CustomLink = styled(SmartLink)`
  ${fontFG16m};
  color: ${colors.white};
  text-transform: none;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  padding: 12px 20px;
  width: 200px;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: ${colors.green};
  }
`;
const MenuItem = styled.li`
  position: relative;
`;
const menuStyle = css`
  padding: 12px 0;
  text-align: left;
  position: absolute;
  background: ${colors.brandBlue};
  box-shadow: 0 3px 6px ${rgba(colors.black, 0.16)};
  transition: all 300ms ease-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
const Menu = styled.ul`
  ${menuStyle};
  top: calc(100%);
  left: -px;
`;
const SubMenu = styled.ul`
  ${menuStyle};
  top: 0;
  left: calc(100% + 1px);
`;

const parentActiveStyle = css`
  color: ${colors.green};
`;
const NavItem = styled.li`
  position: relative;
  margin: auto 0;
  padding: 16px;
  ${Parent} {
    transition: all 300ms ease-out;
    ${(props) => props.active && parentActiveStyle};
  }
`;
const NavList = styled.ul`
  display: flex;
  margin-bottom: 0;
  user-select: none;
  > ${NavItem} {
    text-align: center;
  }
`;
const Nav = styled.nav``;
// const FeaturesRightMenuHeader = styled.div`
//   padding: 18px 24px 4px;
//   color: ${colors.caption};
//   ${fontFGm};
//   font-size: 12px;
//   line-height: 28px;
//   text-transform: uppercase;
// `;
const FeaturesSubMenuItemDescription = styled.div`
  color: ${colors.white};
  ${fontFGr};
  font-size: 12px;
  line-height: 14.4px;
`;
const FeaturesSubMenuItemContent = styled.div``;
const FeaturesSubMenuItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid #242c46;
  }
  ${CustomLink} {
    padding: 16px 24px;
    width: 280px;
    display: flex;
    align-items: center;
    &:hover ${CustomIcon} {
      background-color: ${colors.green};
    }
  }
`;
const FeaturesLeftMenu = styled.ul`
  padding: 8px 0;
`;
// const FeaturesRightMenu = styled.ul`
//   background-color: #f0f0f0;
//   padding-bottom: 14px;
//   ${MenuItem} {
//     padding: 0;
//   }
//   ${CustomLink} {
//     padding: 4px 24px;
//     color: ${colors.darkBlue};
//     ${fontFGr}
//     font-size: 14px;
//     line-height: 28px;
//     &:hover {
//       color: ${colors.green};
//     }
//   }
// `;
const FeaturesSubMenu = styled.div`
  background: ${colors.brandBlue};
  box-shadow: 0 3px 6px ${rgba(colors.black, 0.16)};
  position: absolute;
  top: 0;
  z-index: 1;
  left: calc(100% + 1px);
  transition: all 300ms ease-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  display: flex;
  text-align: left;
  ${FeaturesSubMenuItemDescription} {
    margin-top: 4px;
  }
`;

// const JOBS_PUBLIC_WEBSITE_MENU_ID = "jobs_public_website";
const OUR_SERVICES_MENU_ID = "our_services";

class NavBar extends React.Component {
  state = {
    activeMenu: undefined,
    activeSubMenu: undefined,
  };

  openMenu = (id) => this.setState({ activeMenu: id });
  closeMenu = () => this.setState({ activeMenu: undefined });
  openSubMenu = (id) => this.setState({ activeSubMenu: id });
  closeSubMenu = () => this.setState({ activeSubMenu: undefined });

  renderFeatureSubMenu = (features) => (
    <>
      <FeaturesLeftMenu>
        {features.reduce((arr, feature) => {
          if (feature.description) {
            arr.push(
              <FeaturesSubMenuItem key={feature.id}>
                <CustomLink to={feature.url}>
                  <CustomIcon icon={FEATURE_ICONS[feature.id]} />
                  <FeaturesSubMenuItemContent>
                    {feature.name}
                    <FeaturesSubMenuItemDescription>
                      {feature.description}
                    </FeaturesSubMenuItemDescription>
                  </FeaturesSubMenuItemContent>
                </CustomLink>
              </FeaturesSubMenuItem>
            );
          }
          return arr;
        }, [])}
      </FeaturesLeftMenu>
      {/* <FeaturesRightMenu>
        <FeaturesRightMenuHeader>
          <FormattedMessage id="features" />
        </FeaturesRightMenuHeader>
        {features.reduce((arr, feature) => {
          if (!feature.description) {
            arr.push(
              <MenuItem key={feature.id}>
                <CustomLink to={feature.url}>{feature.name}</CustomLink>
              </MenuItem>
            );
          }
          return arr;
        }, [])}
      </FeaturesRightMenu> */}
    </>
  );

  renderMenuItem = (item) => {
    return (
      <MenuItem
        key={item.id}
        onMouseEnter={item.children ? () => this.openSubMenu(item.id) : null}
        onMouseLeave={item.children ? this.closeSubMenu : null}
        onBlur={this.closeSubMenu}
      >
        <CustomLink
          to={!item.external ? item.url : undefined}
          href={item.external ? item.url : undefined}
          target={item.external ? "_blank" : ""}
        >
          {item.name ? item.name : <FormattedMessage id={item.id} />}
          {item.children && <RightArrowIcon type="right" />}
        </CustomLink>
        {/* Submenu */}
        {item.children &&
          (item.id === OUR_SERVICES_MENU_ID ? (
            <FeaturesSubMenu active={item.id === this.state.activeSubMenu}>
              {this.renderFeatureSubMenu(item.children)}
            </FeaturesSubMenu>
          ) : (
            <SubMenu active={item.id === this.state.activeSubMenu}>
              {item.children.map((subMenuItem) => {
                return (
                  <MenuItem key={subMenuItem.id}>
                    <CustomLink to={subMenuItem.url}>
                      {subMenuItem.name ? (
                        subMenuItem.name
                      ) : (
                        <FormattedMessage id={subMenuItem.id} />
                      )}
                    </CustomLink>
                  </MenuItem>
                );
              })}
            </SubMenu>
          ))}
      </MenuItem>
    );
  };

  render() {
    const { sitemap } = this.props;
    const { activeMenu } = this.state;

    return (
      <Nav {...this.props} onMouseLeave={this.closeMenu}>
        <NavList>
          {/* All nav items except Self Signup CTA */}
          {sitemap
            .filter((navItem) => !navItem.cta)
            .map((navItem) => {
              return (
                <NavItem active={navItem.id === activeMenu} key={navItem.id}>
                  <Parent
                    to={navItem.url || navItem.children[0].url}
                    onMouseEnter={() => this.openMenu(navItem.id)}
                    onMouseLeave={!navItem.children ? this.closeMenu : null}
                    onBlur={this.closeMenu}
                    tabIndex={0}
                  >
                    <FormattedMessage id={navItem.id} />
                  </Parent>
                  {navItem.children && (
                    <Menu
                      onMouseLeave={this.closeMenu}
                      onBlur={this.closeMenu}
                      active={navItem.id === activeMenu}
                    >
                      {navItem.children
                        .filter((child) => child.desktop !== false)
                        .map((child) => this.renderMenuItem(child))}
                    </Menu>
                  )}
                </NavItem>
              );
            })}
        </NavList>
      </Nav>
    );
  }
}

export default NavBar;
