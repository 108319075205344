import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { addLocaleData, IntlProvider } from "react-intl";
import styled, { createGlobalStyle } from "styled-components";
import branch from "branch-sdk";
import reset from "styled-reset";
import enData from "react-intl/locale-data/en";
import idData from "react-intl/locale-data/id";
import thData from "react-intl/locale-data/th";

import Header from "./Header";
import { fontFaces, fontR14r } from "../styles/fonts";
import colors from "../styles/colors";
import breakpoints from "../styles/breakpoints";
import en from "../i18n/en.json";
import id from "../i18n/id.json";
import th from "../i18n/th.json";
import Footer from "./Footer";
import { Context } from "../components/shared/withContext";
import { getUtmContentFromParams } from "./utilities/utmUtils";
import { getLocaleFromUrl } from "../utilities/i18n";
import { getGeoInfoFromIp } from "../utilities/utils";
import { getLocalizedSitemap } from "../sitemap";
import { BRANCH_KEY } from "../config";

const messages = {
  "en-gb": en,
  id: id,
  th: th,
};

addLocaleData([...enData, ...idData, ...thData]);

const CustomHeader = styled(Header)``;

const Main = styled.main`
  padding-top: 96px;

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 110px;
  }
`;

const Container = styled.div`
  ${CustomHeader} {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${fontFaces};

  body {
    color: ${colors.black};
    ${fontR14r};

    // Worker app download banner that only shows in mobile view
    #branch-banner-iframe {
      top: 100px !important;
      z-index: 2 !important;
    }
  }

  * {
    box-sizing: border-box;
  }
`;

class Layout extends React.Component {
  state = {
    contextContent: {},
  };

  async componentDidMount() {
    const { location } = this.props;
    const utmContent = getUtmContentFromParams(location.search);

    let countryCode;
    try {
      const geoInfo = await getGeoInfoFromIp();
      countryCode = geoInfo?.country_code;
    } catch (error) {
      // Do nothing
    } finally {
      this.setState({
        contextContent: {
          utmContent,
          countryCode,
        },
      });
    }

    // window API (localStorage) is not available during build time
    if (typeof window !== "undefined") {
      // Initialize worker app download link
      branch.init(BRANCH_KEY, (err, data) => {
        branch.link(data?.data, function (err, link) {
          localStorage.setItem("branchLink", link);
        });
      });
    }
  }

  render() {
    const { location, children, pageData = {} } = this.props;
    const { contextContent } = this.state;

    const {
      navigation_cta_button_text: navigationCtaButtonText,
      navigation_cta_button_url: navigationCtaButtonUrl,
    } = pageData;

    const url = location.pathname;
    const locale = getLocaleFromUrl(url);
    return (
      <StaticQuery
        query={graphql`
          query SiteQuery {
            allPrismicSite {
              edges {
                node {
                  lang
                  data {
                    email
                    social_links {
                      link
                      image {
                        alt
                        copyright
                        url
                      }
                    }
                  }
                }
              }
            }
            allPrismicFeaturesIndividualPage(
              sort: { order: ASC, fields: data___feature_navigation_order }
              filter: { data: { feature_navigation_enabled: { eq: true } } }
            ) {
              edges {
                node {
                  uid
                  lang
                  data {
                    feature_name
                    feature_navigation_title
                    feature_navigation_description
                    feature_navigation_enabled
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          // Get the prismic data for the current locale key
          const siteData = data.allPrismicSite.edges.filter(
            (edge) => edge.node.lang === locale.key
          )[0].node.data;
          const featurePageData =
            data.allPrismicFeaturesIndividualPage.edges.filter(
              (edge) => edge.node.lang === locale.key
            );

          const sitemap = getLocalizedSitemap(
            locale.key,
            navigationCtaButtonText,
            navigationCtaButtonUrl
          );
          // Sitemap for footer
          let flatSitemap = sitemap;
          // Sitemap for desktop and mobile nav
          let nestedSitemap = sitemap;

          // Append feature page menus to sitemaps
          if (featurePageData.length > 0) {
            const featureSitemap = featurePageData.map(({ node }) => {
              return {
                id: node.uid,
                url: `/${node.uid}`,
                name:
                  node.data.feature_navigation_title || node.data.feature_name,
                description: node.data.feature_navigation_description,
              };
            });

            // Sitemap for desktop and mobile nav
            nestedSitemap = nestedSitemap.map((menuItem) =>
              menuItem.id === "for_business"
                ? {
                    ...menuItem,
                    children: [
                      ...menuItem.children.slice(0, 1),
                      {
                        id: "our_services",
                        children: [...featureSitemap],
                      },
                      ...menuItem.children.slice(-1),
                    ],
                  }
                : menuItem
            );
            // Sitemap for footer
            flatSitemap = flatSitemap.map((menuItem) => {
              if (menuItem.id === "for_business") {
                return {
                  ...menuItem,
                  children: [
                    ...featureSitemap.filter((feature) => feature.description),
                  ],
                };
              }
              return menuItem;
            });
          }

          return (
            <Context.Provider value={contextContent}>
              <IntlProvider locale={locale.key} messages={messages[locale.key]}>
                <Container>
                  <Helmet>
                    <meta
                      name="google-site-verification"
                      content="h0jufVraztpqThH1lwaCO5-UiLiDQX0V3wbwd0KMXZU"
                    />
                  </Helmet>
                  <GlobalStyle />
                  <CustomHeader
                    url={url}
                    locale={locale}
                    mobileSitemap={nestedSitemap.filter(
                      (item) => item.nav !== false
                    )}
                    mainSitemap={nestedSitemap.filter(
                      (item) => item.nav !== false
                    )}
                  />
                  <Main>{children}</Main>
                  <Footer
                    socialLinks={siteData.social_links}
                    url={url}
                    locale={locale}
                    siteData={siteData}
                    sitemap={flatSitemap.filter(
                      (item) => item.footer !== false
                    )}
                  />
                </Container>
              </IntlProvider>
            </Context.Provider>
          );
        }}
      />
    );
  }
}

export default Layout;
