import styled, {css} from 'styled-components';
import breakpoints from './breakpoints';

export const gridContentCellMobile = css`minmax(20px, 140px)`;
export const gridContentCellDesktop = css`minmax(20px, 70px)`;

export const grids = {
  xxs: css`
    display: grid;
    grid-template-columns: minmax(10px, 1fr) repeat(4, ${gridContentCellMobile}) minmax(10px, 1fr);
    grid-column-gap: 14px;
  `,
  xs: css``,
  sm: css``,
  md: css`
    display: grid;
    grid-template-columns:
      minmax(15px, 1fr) repeat(4, ${gridContentCellMobile})
      minmax(15px, 1fr);
    grid-column-gap: 14px;
  `,
  lg: css`
    display: grid;
    grid-template-columns:
      minmax(30px, 1fr) repeat(12, ${gridContentCellDesktop})
      minmax(30px, 1fr);
    grid-column-gap: 40px;
  `,
};

export const gridCss = css`
  ${grids.xxs};

  @media (min-width: ${breakpoints.xs}) {
    ${grids.xs};
  }
  @media (min-width: ${breakpoints.sm}) {
    ${grids.sm};
  }
  @media (min-width: ${breakpoints.md}) {
    ${grids.md};
  }
  @media (min-width: ${breakpoints.lg}) {
    ${grids.lg};
  }
`;

export default styled.div`
  ${gridCss};

  > * {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    > * {
      grid-column: 2 / span 12;
    }
  }
`;
