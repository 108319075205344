import React, { useState } from "react";
import styled from "styled-components";
import { BUSINESS_LOGIN_LINK, HUBSPOT_FORM_ID } from "../config";
import routes from "../routes";
import colors from "../styles/colors";
import { fontR12m, fontFGm, fontFGl } from "../styles/fonts";
import { FormattedMessage } from "react-intl";
import SmartLink from "./shared/SmartLink";
import Grid from "../styles/Grid";
import chevronDown from "../assets/images/down-chevron-small.svg";
import HubspotFormModal from "./shared/HubspotFormModal";
import { withContext } from "./shared/withContext";
import CTAButton from "./shared/CTAButton";
import { appendUtmContentToUrl } from "./utilities/utmUtils";
import { countryCodes, navigationIds } from "../constants";
import { BUSINESS_SIGNUP_LINK } from "../config";

const SelfSignupCTA = styled(CTAButton)`
  width: 100%;
`;
const LoginCTA = styled(CTAButton)`
  width: 100%;
  margin-top: 16px;
  background-color: transparent;
  border: 1px solid ${colors.white};
  &:hover {
    background-color: ${colors.white};
    border: 1px solid ${colors.white};
    color: ${colors.brandBlue};
  }
`;
const CustomLink = styled(SmartLink)`
  ${fontFGm};
  font-size: 20px;
  line-height: 28px;
  color: ${colors.white};
  text-decoration: none;
  &:hover {
    color: ${colors.green};
  }
`;
const SubMenuItem = styled.li``;
const AccordionLink = styled.li`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  ${CustomLink} {
    ${fontFGl};
    font-size: 18px;
    line-height: 26px;
  }
`;
const AccordionContent = styled.ul`
  padding: ${(props) => (props.active ? "8px 0 0 16px" : 0)};
  transition: all 600ms ease;
  max-height: ${(props) => (props.active ? "500px" : "0")};
  overflow: hidden;
`;
const Accordion = styled.li`
  position: relative;
  cursor: pointer;
  > ${CustomLink} {
    position: relative;
    display: block;
    width: 100%;
    color: ${(props) => props.active && colors.green};
  }
  > ${CustomLink}:after {
    content: "";
    mask: url(${chevronDown});
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    height: 8px;
    width: 22px;
    background-color: ${(props) =>
      props.active ? colors.green : colors.white};
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 300ms ease-out;
    transform: translateY(-50%);
    transform-origin: 50% 50%;
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0deg)")};
  }
  &:hover {
    ${CustomLink}:after {
      background-color: ${colors.green};
    }
  }
`;

const SubMenu = styled.ul`
  ${SubMenuItem},
  ${Accordion} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Parent = styled.div`
  ${fontR12m};
  color: ${colors.white};
  opacity: 0.7;
  text-transform: uppercase;
`;
const MenuItem = styled.li`
  ${Parent} {
    margin-bottom: 16px;
  }
`;
const Menu = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 40px;

  ${MenuItem}:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  ${MenuItem}:nth-child(2) {
    grid-area: 2 / 1 / 3 / 3;
  }
  ${MenuItem}:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }
  ${MenuItem}:nth-child(4) {
    grid-area: 4 / 1 / 4 / 3;
  }
`;
const Nav = styled.nav``;
const Container = styled(Grid)`
  background: ${colors.brandBlue};

  ${Nav} {
    grid-column: 2 / span 4;
    padding: 36px 0 60px;
  }
`;

const MobileNavAccordion = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Accordion active={isOpen} onClick={() => setOpen(!isOpen)}>
      <CustomLink>
        {props.data.name ? (
          props.data.name
        ) : (
          <FormattedMessage id={props.data.id} />
        )}
      </CustomLink>
      <AccordionContent active={isOpen}>
        {props.data.id === "our_services" ? (
          <AccordionLink key={props.data.id}>
            <CustomLink to={props.data.url}>
              <FormattedMessage id={"all_features"} />
            </CustomLink>
          </AccordionLink>
        ) : props.data.id === "industries" ? (
          <AccordionLink key={props.data.id}>
            <CustomLink to={props.data.url}>
              <FormattedMessage id={"all_industries"} />
            </CustomLink>
          </AccordionLink>
        ) : null}
        {props.data.children.map((child) => (
          <AccordionLink key={child.id}>
            <CustomLink to={child.url}>
              {child.name ? child.name : <FormattedMessage id={child.id} />}
            </CustomLink>
          </AccordionLink>
        ))}
      </AccordionContent>
    </Accordion>
  );
};

const StandardMenuItem = (props) => {
  let href = props.data.external ? props.data.url : undefined;
  if (props.data.id === "i_want_to_hire" && props.utmContent) {
    href = appendUtmContentToUrl(props.data.url, props.utmContent);
  }
  return (
    <SubMenuItem key={props.data.id}>
      <CustomLink
        to={!props.data.external ? props.data.url : undefined}
        href={href}
        target={props.data.external ? "_blank" : ""}
        onClick={() => {
          props.onItemClicked();

          // if (props.data.id === "i_want_to_hire") {
          //   window.analytics.track("Clicked Manage Workforce", {
          //     label: "Get Started"
          //   });
          // }
          // if (props.data.id === "i_want_to_work") {
          //   window.analytics.track("Clicked Download Now", {
          //     label: "Get Started"
          //   });
          // }
        }}
      >
        {props.data.name ? (
          props.data.name
        ) : (
          <FormattedMessage id={props.data.id} />
        )}
      </CustomLink>
    </SubMenuItem>
  );
};

const MobileNav = ({
  onItemClicked,
  sitemap,
  context: { utmContent, countryCode },
  url,
  ...props
}) => {
  const [bookDemoModalOpen, setBookDemoModalOpen] = useState(false);
  const mainNavCTA = sitemap.find(
    (item) => item.id === navigationIds.MAIN_NAV_CTA
  );
  const jobsPublicWebsiteButton = sitemap.find(
    (item) => item.id === "jobs_public_website"
  );
  const isWorkersPage = url.includes(routes.workers);
  const isSG = countryCode === countryCodes.SINGAPORE;

  return (
    <Container {...props}>
      <Nav>
        <Menu>
          {sitemap
            .filter((menuItem) => !menuItem.cta)
            .map((menuItem) => (
              <MenuItem key={menuItem.id}>
                <Parent>
                  <FormattedMessage id={menuItem.id} />
                </Parent>
                <SubMenu>
                  {menuItem.children &&
                    menuItem.children
                      .filter((child) => child.mobile !== false)
                      .map((child) => {
                        if (
                          child.id === "our_services" ||
                          child.id === "industries" ||
                          child.id === "resources"
                        ) {
                          return (
                            <MobileNavAccordion key={child.id} data={child} />
                          );
                        } else {
                          return (
                            <StandardMenuItem
                              key={child.id}
                              data={child}
                              onItemClicked={onItemClicked}
                              utmContent={utmContent}
                            />
                          );
                        }
                      })}
                  {menuItem.id === "for_business" && (
                    <>
                      {isSG && !isWorkersPage ? (
                        <SelfSignupCTA
                          href={BUSINESS_SIGNUP_LINK}
                          utmContent={utmContent}
                          target="_blank"
                          rel="noopener"
                        >
                          <FormattedMessage id="get_started" />
                        </SelfSignupCTA>
                      ) : isWorkersPage ? (
                        <SelfSignupCTA
                          href={jobsPublicWebsiteButton.url}
                          target="_blank"
                          rel="noopener"
                        >
                          <FormattedMessage id="find_jobs" />
                        </SelfSignupCTA>
                      ) : (
                        <SelfSignupCTA
                          href={mainNavCTA.url}
                          target="_blank"
                          rel="noopener"
                        >
                          <FormattedMessage id={mainNavCTA.id + "_text"} />
                        </SelfSignupCTA>
                      )}
                      {isSG && (
                        <LoginCTA href={BUSINESS_LOGIN_LINK} rel="noopener">
                          <FormattedMessage id="client_login" />
                        </LoginCTA>
                      )}
                    </>
                  )}
                </SubMenu>
              </MenuItem>
            ))}
        </Menu>
      </Nav>
      <HubspotFormModal
        targetId="get_started_hubspot_form"
        formId={HUBSPOT_FORM_ID}
        visible={bookDemoModalOpen}
        onCancel={() => setBookDemoModalOpen(false)}
      />
    </Container>
  );
};

export default withContext(MobileNav);
