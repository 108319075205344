import { css } from "styled-components";

import foundersGrotMedium from "../assets/fonts/FoundersGroteskWeb-Medium.woff";
import foundersGrotLight from "../assets/fonts/FoundersGroteskWeb-Light.woff";

import breakpoints from "./breakpoints";
import colors from "./colors";

const fontDisplay = "swap";

const fontFamilyFoundersGrot = "Founders Grotesk";
const fontFamilyRoboto = "Roboto";

const fonts = {
  foundersGrot: {
    family: `${fontFamilyFoundersGrot}, Sans Serif`,
    weights: {
      medium: 500,
      regular: 400,
      light: 300,
    },
  },
  roboto: {
    family: `${fontFamilyRoboto}, Sans Serif`,
    weights: {
      black: 900,
      medium: 500,
      regular: 400,
    },
  },
};

export const fontFaces = css`
  @font-face {
    src: url(${foundersGrotMedium});
    font-family: ${fontFamilyFoundersGrot};
    font-display: ${fontDisplay};
    font-weight: ${fonts.foundersGrot.weights.medium};
  }

  @font-face {
    src: url(${foundersGrotLight});
    font-family: ${fontFamilyFoundersGrot};
    font-display: ${fontDisplay};
    font-weight: ${fonts.foundersGrot.weights.light};
  }
`;

export const fontTitleRegular = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.regular};
  color: ${colors.title};
`;

/* Header Text - Founders Grot */
/* Header 1 */
export const fontFG52m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 34px;
  line-height: 42px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 52px;
    line-height: 60px;
  }
`;
export const fontH1 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 34px;
  line-height: 42px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 52px;
    line-height: 60px;
  }
`;

/* Header 2 */
export const fontFG46m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 30px;
  line-height: 38px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 46px;
    line-height: 54px;
  }
`;
export const fontH2 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 30px;
  line-height: 38px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 46px;
    line-height: 54px;
  }
`;

/* to remove */
export const fontFG40m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 40px;
  line-height: 42px;
`;

/* Header 3 - Medium */
export const fontFG38m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 28px;
  line-height: 36px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 38px;
    line-height: 46px;
  }
`;
export const fontH3 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 28px;
  line-height: 36px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 38px;
    line-height: 46px;
  }
`;

/* Header 4 - Medium */
export const fontFG30m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 26px;
  line-height: 34px;
  color: ${colors.title};

  @media (min-width: ${breakpoints.lg}) {
    font-size: 30px;
    line-height: 38px;
  }
`;
export const fontH4 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 26px;
  line-height: 34px;
  color: ${colors.title};

  @media (min-width: ${breakpoints.lg}) {
    font-size: 30px;
    line-height: 38px;
  }
`;
export const fontH4Light = css`
  ${fontH4};
  font-weight: ${fonts.foundersGrot.weights.light};
`;

/* to remove */
export const fontFG28m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 28px;
  line-height: 36px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 38px;
  }
`;

/* Header 5 - medium */
export const fontFG24m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 22px;
  line-height: 30px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const fontH5 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 22px;
  line-height: 30px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const fontH5Light = css`
  ${fontH5};
  font-weight: ${fonts.foundersGrot.weights.light};
`;

/* Header 5 - light */
export const fontFG24l = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
  font-size: 22px;
  line-height: 30px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

/* Header 6 - medium */
export const fontFG20m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const fontH6 = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  color: ${colors.title};
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const fontH6Light = css`
  ${fontH6};
  font-weight: ${fonts.foundersGrot.weights.light};
`;

/* Header 6 - light */
export const fontFG20l = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 20px;
    line-height: 28px;
  }
`;

/* To remove below */
export const fontFG18m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 18px;
  line-height: 26px;
`;

export const fontFG18l = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
  font-size: 18px;
  line-height: 26px;
`;

export const fontFG16m = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
  font-size: 16px;
  line-height: 24px;
`;

export const fontFG16l = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
  font-size: 16px;
  line-height: 24px;
`;

export const fontFG14l = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
`;

export const fontFGr = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.regular};
`;
export const fontFGm = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.medium};
`;
export const fontFGl = css`
  font-family: ${fonts.foundersGrot.family};
  font-weight: ${fonts.foundersGrot.weights.light};
`;
/* Up to here */

/* Body Text - Roboto */

export const fontR14m = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.medium};
  font-size: 14px;
  line-height: 22px;
`;

export const fontR14r = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.regular};
  font-size: 14px;
  line-height: 22px;
`;

export const fontR12r = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.regular};
  font-size: 12px;
  line-height: 15px;
`;

export const fontR12m = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.medium};
  font-size: 12px;
  line-height: 15px;
`;

/* Body - Medium */
export const fontR16m = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.medium};
  font-size: 16px;
  line-height: 24px;
`;
/* Body - Regular */
export const fontR16r = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.regular};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.body};
`;

export const fontBodyRegular = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.regular};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.body};
`;
export const fontBodyStrong = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.medium};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.body};
`;
export const fontBody2 = css`
  font-family: ${fonts.roboto.family};
  font-weight: ${fonts.roboto.weights.regular};
  font-size: 14px;
  line-height: 22px;
  color: ${colors.body};
`;
