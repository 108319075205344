import React from "react";
import LocalizedLink from "./LocalizedLink";

// TODO This component is redundant. It only helps when we use styled components, but is a bad pattern
const SmartLink = ({ to, href, ...props }) => {
  if (to) {
    return <LocalizedLink to={to} {...props} />;
  }

  return (
    <a href={href} {...props}>
      {props.children}
    </a>
  );
};

export default SmartLink;
