import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${colors.black};
`;

const LineContainer = styled.div`
  width: 16px;
  height: 12px;
  position: relative;

  ${Line} {
    position: absolute;
    transition: background 500ms ease-out,
      ${(props) =>
        props.open
          ? `top 250ms ease-out, transform 250ms ease-out 250ms`
          : `transform 250ms ease-out, top 250ms ease-out 250ms`};
    &:nth-child(1) {
      top: ${(props) => (props.open ? "50%" : "0")};
      transform: translateY(-50%)
        ${(props) => (props.open ? `rotate(-45deg)` : ``)};
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%)
        ${(props) => (props.open ? `rotate(45deg)` : ``)};
    }
    &:nth-child(3) {
      top: ${(props) => (props.open ? "50%" : "100%")};
      transform: translateY(-50%)
        ${(props) => (props.open ? `rotate(-45deg)` : ``)};
    }
  }
`;

const CustomContainer = styled.div`
  padding: 10px;
`;

const HamburgerButton = ({ open, color, ...props }) => (
  <CustomContainer {...props}>
    <LineContainer open={open}>
      <Line color={color} />
      <Line color={color} />
      <Line color={color} />
    </LineContainer>
  </CustomContainer>
);

export default HamburgerButton;
