import React from "react";

export const Context = React.createContext();

export function withContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Context.Consumer>
        {state => <Component {...props} context={state} />}
      </Context.Consumer>
    );
  };
}
