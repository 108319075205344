import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { BUSINESS_LOGIN_LINK, BUSINESS_SIGNUP_LINK } from "../config";
import { fontFG16m } from "../styles/fonts";
import colors from "../styles/colors";
import { countryCodes, navigationIds } from "../constants";
import routes from "../routes";

import SmartLink from "./shared/SmartLink";
import CTAButton from "./shared/CTAButton";
import { withContext } from "./shared/withContext";

const JOBS_PUBLIC_WEBSITE_MENU_ID = "jobs_public_website";

const LoginLink = styled(SmartLink)`
  ${fontFG16m};
  color: ${colors.brandBlue};
  padding: 8px 0;
  position: relative;
  &:hover {
    color: ${colors.green};
  }
`;

const CustomCTAButton = styled(CTAButton)`
  padding: 12px 24px;
`;

const CTAMenu = ({
  sitemap,
  url,
  active,
  context: { utmContent, countryCode },
  ...props
}) => {
  const isSG = countryCode === countryCodes.SINGAPORE;
  const isWorkersPage = url.includes(routes.workers);
  const mainNavCTA = sitemap.find(
    (item) => item.id === navigationIds.MAIN_NAV_CTA
  );
  const jobsPublicWebsiteButton = sitemap.find(
    (item) => item.id === JOBS_PUBLIC_WEBSITE_MENU_ID
  );

  const renderCTAButton = () => {
    // mainNavCTA.text is set from prismic, overrides everything
    if (mainNavCTA.text) {
      return (
        <CustomCTAButton
          href={mainNavCTA.url}
          utmContent={utmContent}
          target="_blank"
          rel="noopener"
        >
          {mainNavCTA.text}
        </CustomCTAButton>
      );
    }
    // Else go to fall back pre-exsting logi
    if (isSG && !isWorkersPage) {
      return (
        <CustomCTAButton
          href={BUSINESS_SIGNUP_LINK}
          utmContent={utmContent}
          target="_blank"
          rel="noopener"
        >
          <FormattedMessage id="get_started" />
        </CustomCTAButton>
      );
    }
    if (isWorkersPage) {
      return (
        <CustomCTAButton
          href={jobsPublicWebsiteButton.url}
          target="_blank"
          rel="noopener"
        >
          <FormattedMessage id="find_jobs" />
        </CustomCTAButton>
      );
    }
    return (
      <CustomCTAButton href={mainNavCTA.url} target="_blank" rel="noopener">
        <FormattedMessage id={mainNavCTA.id + "_text"} />
      </CustomCTAButton>
    );
  };

  return (
    <div {...props}>
      {isSG && (
        <LoginLink style={{ marginRight: 24 }} href={BUSINESS_LOGIN_LINK}>
          <FormattedMessage id="client_login" />
        </LoginLink>
      )}
      {renderCTAButton()}
    </div>
  );
};

export default withContext(CTAMenu);
