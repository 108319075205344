export const sliceTypes = {
  HIGHLIGHTS: "highlights",
  TITLE_AND_PARAGRAPH: "title_and_paragraph",
  LOGOS: "logos",
  HIGHLIGHTS_WITH_IMAGES: "highlights_with_images",
  CTA_BANNER: "cta_banner",
  LISTS_IN_GRID: "lists_in_grid",
  QUOTES: "quotes",
  SLIDER: "slider",
  SLIDER_WITH_BACKGROUND: "slider_with_background",
  VIDEO_AND_QUOTE: "video_and_quote",
  CTA_BANNER_WITH_IMAGE: "cta_banner_with_image",
  STEPS: "steps",
  FEATURES: "features",
  FEATURES_IN_GRID: "features_in_grid",
  RICH_TEXT_FIELD: "rich_text_field",
  RICH_TEXT_FIELD_GRID: "rich_text_field_grid",
  BLOG_QUOTE_CALL_OUT: "blog_quote_call_out",
  BLOG_QUOTE_EMBEDDED: "blog_quote_embedded",
  BLOG_CALL_OUT: "blog_call_out",
  BLOG_CTA_BANNER: "blog_cta_banner",
};

export const utmTypes = {
  SOURCE: "utm_source",
  MEDIUM: "utm_medium",
  CAMPAIGN: "utm_campaign",
};

export const navigationIds = {
  MAIN_NAV_CTA: "main_nav_cta",
};

export const countryCodes = {
  SINGAPORE: "SG",
  THAILAND: "TH",
  INDONESIA: "ID",
};
