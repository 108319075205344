import React from "react";
import styled from "styled-components";
import { fontR12m } from "../../styles/fonts";
import colors from "../../styles/colors";
import SmartLink from "./SmartLink";

const CustomLink = styled(SmartLink)`
  ${fontR12m};
  display: inline-block;
  text-transform: uppercase;
  color: ${colors.link};
`;

const StandardLink = ({ ...props }) => <CustomLink {...props} />;

export default StandardLink;
