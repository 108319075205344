import routes from "./routes";
import {
  helpCentreForWorkersLinks,
  helpCentreLinks,
  jobsPublicWebsiteLinks,
  workmateSolutionsHubspotFormLinks,
} from "./config";
import { navigationIds } from "./constants";

export const getLocalizedSitemap = (
  localeKey,
  navigationCtaButtonText = undefined,
  navigationCtaButtonUrl = undefined
) => [
  {
    id: "for_business",
    children: [
      {
        id: "overview",
        url: routes.home,
        desktop: false,
      },
      {
        id: "resources",
        children: [
          {
            id: "blog_desktop_nav",
            url: routes.blog,
          },
          {
            id: "ebooks",
            url: routes.eBooks,
          },
        ],
      },
    ],
  },
  {
    id: "for_workers",
    footer: false,
    children: [
      {
        id: "overview",
        url: routes.workers,
      },
      {
        id: "get_help",
        external: true,
        url: helpCentreForWorkersLinks[localeKey],
      },
    ],
  },
  {
    id: "for_workers",
    nav: false,
    children: [
      {
        id: "find_jobs",
        external: true,
        url: jobsPublicWebsiteLinks[localeKey],
      },
      {
        id: "get_help",
        external: true,
        url: helpCentreForWorkersLinks[localeKey],
      },
    ],
  },
  {
    id: "our_company",
    children: [
      {
        id: "about_us",
        url: routes.about,
      },
      {
        id: "careers",
        url: routes.careers,
      },
      {
        id: "press",
        url: routes.press,
      },
    ],
  },
  {
    id: "resources",
    nav: false,
    children: [
      {
        id: "blog",
        url: routes.blog,
      },
      {
        id: "ebooks",
        url: routes.eBooks,
      },
      {
        id: "help_center",
        external: true,
        url: helpCentreLinks[localeKey],
      },
    ],
  },
  {
    id: navigationIds.MAIN_NAV_CTA,
    footer: false,
    cta: true,
    external: true,
    url: navigationCtaButtonUrl || workmateSolutionsHubspotFormLinks[localeKey],
    text: navigationCtaButtonText,
  },
  {
    id: "jobs_public_website",
    footer: false,
    cta: true,
    external: true,
    url: jobsPublicWebsiteLinks[localeKey],
  },
];
