import React from "react";
import styled from "styled-components";

import brandLogoImage from "../assets/images/brand-logo.svg";
import Grid from "../styles/Grid";
import HamburgerButton from "./HamburgerButton";
import colors from "../styles/colors";
import breakpoints from "../styles/breakpoints";
import Link from "./shared/StandardLink";
import routes from "../routes";

import NavBar from "./NavBar";
import MobileNav from "./MobileNav";
import LanguageSelector from "./shared/LanguageSelector";
import CTAMenu from "./CTAMenu";

const Logo = styled(Link)`
  height: 32px;

  img {
    width: 130px;
    height: 100%;
  }
`;

const LogoLanguageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 226px;
  ${Logo} {
    margin-right: 30px;
  }
`;

const Button = styled(HamburgerButton)``;

const CustomCTAMenu = styled(CTAMenu)``;

const CustomNavBar = styled(NavBar)``;

const CustomMobileNav = styled(MobileNav)`
  transition: all 300ms ease-out;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  overflow-y: scroll;
`;

const CustomLanguageSelector = styled(LanguageSelector)``;

const FlexContainer = styled.div``;

const Container = styled(Grid)`
  height: 96px;
  background: ${colors.white};
  align-items: center;

  ${FlexContainer} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${CustomNavBar}, ${CustomCTAMenu} {
    display: none;
  }

  ${CustomMobileNav} {
    position: fixed;
    top: 96px;
    left: 0;
    width: 100%;
    bottom: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 110px;

    ${CustomNavBar} {
      display: block;
    }

    ${CustomCTAMenu} {
      display: flex;
      justify-content: flex-end;
      width: 226px;
    }

    ${CustomMobileNav}, ${Button} {
      display: none;
    }
  }
`;

export default class Header extends React.Component {
  state = {
    mobileNavOpen: false,
  };

  toggleMobileNav = () => {
    this.setState((prevState) => ({
      mobileNavOpen: !prevState.mobileNavOpen,
    }));
  };

  render() {
    const { locale, mobileSitemap, mainSitemap, url, ...props } = this.props;

    return (
      <Container {...props}>
        <FlexContainer>
          <LogoLanguageContainer>
            <Logo to={routes.home}>
              <img src={brandLogoImage} alt="Brand Logo" id="header-logo" />
            </Logo>
            <CustomLanguageSelector url={url} locale={locale} />
          </LogoLanguageContainer>
          <CustomNavBar url={url} sitemap={mainSitemap} />
          <CustomCTAMenu url={url} sitemap={mainSitemap} />
          <CustomMobileNav
            url={url}
            sitemap={mobileSitemap}
            open={this.state.mobileNavOpen}
            onItemClicked={() => this.setState({ mobileNavOpen: false })}
          />
          <Button
            open={this.state.mobileNavOpen}
            onClick={this.toggleMobileNav}
          />
        </FlexContainer>
      </Container>
    );
  }
}
