import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { fontFG30m, fontFG16m } from "../../styles/fonts";
import colors from "../../styles/colors";
import { HUBSPOT_PORTAL_ID } from "../../config";

const HubspotForm = styled.div`
  text-align: center;
  .form-columns-0 {
    margin: 24px auto;
    h1 {
      ${fontFG30m};
    }
  }
  .form-columns-2 {
    text-align: left;
    max-width: 100%;
  }
  .form-columns-1 {
    text-align: left;
    max-width: 100%;
  }
  .hs-form-required {
    display: none;
  }
  .hs-input {
    width: 100% !important;
    height: 50px;
    margin: 12px 0;
    padding-left: 15px;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    resize: none;
    outline: none;
    ::placeholder {
      color: ${colors.border};
    }
  }
  .error {
    border: 1px solid ${colors.red} !important;
  }
  .hs-error-msgs {
    text-align: left;
    .hs-error-msg {
      color: ${colors.red};
    }
  }
  .hs_recaptcha {
    margin-top: 12px;
    display: inline-flex;
    .grecaptcha-badge {
      box-shadow: none !important;
    }
  }
  .hs-button {
    ${fontFG16m};
    height: 46px;
    margin-top: 6px;
    padding: 10px 20px;
    color: ${colors.white};
    background-color: ${colors.green};
    border-radius: 4px;
    border: none;
  }
`;

class HubspotFormModal extends React.Component {
  state = {
    formCreated: false
  };

  // Initialize hubspot form when modal is clicked open first time
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.visible !== this.props.visible &&
      this.props.visible &&
      !prevState.formCreated
    ) {
      this.initializeForm();
    }
  };

  initializeForm = () => {
    const { targetId, formId } = this.props;
    window.hbspt.forms.create({
      portalId: HUBSPOT_PORTAL_ID,
      formId: formId,
      target: `#${targetId}`
    });
    this.setState({ formCreated: true });
  };

  render() {
    const { visible, onCancel, targetId } = this.props;

    return (
      <Modal
        mask
        centered
        footer={null}
        width={736}
        bodyStyle={{ padding: 40 }}
        visible={visible}
        onCancel={onCancel}
      >
        <HubspotForm id={targetId} />
      </Modal>
    );
  }
}

export default HubspotFormModal;
