import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import routes from "../routes";
import breakpoints from "../styles/breakpoints";
import Grid from "../styles/Grid";
import { fontFG18l, fontFG18m } from "../styles/fonts";
import colors from "../styles/colors";
import brandLogoImage from "../assets/images/brand-logo.svg";
import SmartLink from "./shared/SmartLink";
import LocalizedLink from "./shared/LocalizedLink";

const CustomLink = styled(SmartLink)`
  ${fontFG18l};
  display: inline-block;
  text-decoration: none;
  color: ${colors.black};
`;

const Logo = styled.img`
  display: inline-block;
  width: 110px;
  height: 24px;
`;

const Copyright = styled.div`
  ${fontFG18l};
  color: ${colors.darkGrey};
  margin: 26px 0;
`;

const SocialItem = styled.a`
  display: inline-block;
  > img {
    height: 18px;
    width: 18px;
  }
`;

const SocialLinksContainer = styled.div`
  ${SocialItem} {
    margin-right: 15px;
  }
  ${SocialItem} last-child {
    margin-right: 0;
  }
`;

const LogoColumn = styled.div``;

const Email = styled.a`
  ${fontFG18l};
`;

const PrivacyPolicy = styled.div`
  ${fontFG18l};

  span {
    color: ${colors.darkGrey};
  }

  a {
    text-decoration: none;
    color: ${colors.darkGrey};
    &:visited {
      color: ${colors.darkGrey};
    }
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubItem = styled.li`
  ${CustomLink} {
    line-height: 36px;
  }
`;

const SubItems = styled.ul``;

const Parent = styled.div`
  ${fontFG18m};
  line-height: 36px;
`;

const SitemapItem = styled.li`
  ${Parent}:nth-of-type (2) {
    margin-top: 32px;
  }
`;

const Sitemap = styled.ul``;

const Container = styled(Grid)`
  margin-top: 59px;

  ${LogoColumn} {
    grid-column: 2 / span 4;
  }

  ${Sitemap} {
    margin: 40px 0;
    grid-column: 2 / span 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
  }

  ${Bottom} {
    grid-column: 2 / span 4;
    padding: 15px 0 30px 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${LogoColumn} {
      grid-column: 2 / span 3;
    }

    ${Sitemap} {
      margin: 0;
      grid-row: 1;
      grid-column: 5 / span 9;
      display: flex;
      justify-content: flex-end;

      ${SitemapItem} {
        width: ${(1 / 4) * 100}%;
      }
    }

    ${Bottom} {
      grid-column: 2 / span 12;
      padding: 68px 0 30px 0;
    }
  }
`;

function SitemapFooterColumn(props) {
  return (
    <SitemapItem>
      <Parent>
        <FormattedMessage id={props.item.id} />
      </Parent>
      <SubItems>
        {props.item.children &&
          props.item.children.map((child) => (
            <SubItem key={child.id}>
              <CustomLink
                to={!child.external ? child.url : undefined}
                href={child.external ? child.url : undefined}
                target={child.external ? "_blank" : ""}
                rel={child.external ? "noopener" : ""}
              >
                {child.name ? child.name : <FormattedMessage id={child.id} />}
              </CustomLink>
            </SubItem>
          ))}
      </SubItems>
      {props.item.footer_addon && (
        <>
          <Parent style={{ marginTop: 24 }}>
            <FormattedMessage id={props.item.footer_addon.id} />
          </Parent>
          <SubItems>
            {props.item.footer_addon.children.map((child) => (
              <SubItem key={child.id}>
                <CustomLink
                  to={!child.external ? child.url : undefined}
                  href={child.external ? child.url : undefined}
                  target={child.external ? "_blank" : ""}
                  rel={child.external ? "noopener" : ""}
                >
                  {child.name ? child.name : <FormattedMessage id={child.id} />}
                </CustomLink>
              </SubItem>
            ))}
          </SubItems>
        </>
      )}
    </SitemapItem>
  );
}

const Footer = ({ url, locale, socialLinks, siteData, sitemap, ...props }) => (
  <Container {...props}>
    <LogoColumn>
      <LocalizedLink to={routes.home}>
        <Logo src={brandLogoImage} alt="Brand Logo" />
      </LocalizedLink>
      <Copyright>
        &copy; Helpster Pte Ltd. <br></br> All Rights Reserved,{" "}
        {new Date().getFullYear()}.
      </Copyright>
      <SocialLinksContainer>
        {socialLinks.map((item, index) => (
          <SocialItem
            key={index}
            href={item.link}
            target="_blank"
            rel="noopener"
          >
            <img src={item.image.url} alt="social-links" />
          </SocialItem>
        ))}
      </SocialLinksContainer>
    </LogoColumn>
    <Sitemap>
      {sitemap.map((sitemapItem) => (
        <SitemapFooterColumn key={sitemapItem.id} item={sitemapItem} />
      ))}
    </Sitemap>
    <Bottom>
      <Email href={`mailto:${siteData.email}`} target="_blank" rel="noopener">
        {siteData.email}
      </Email>
      <PrivacyPolicy>
        <LocalizedLink to={routes.privacyPolicy}>
          <FormattedMessage id="privacy_policy" />
        </LocalizedLink>
        <span> • </span>
        <LocalizedLink to={routes.workerTerms}>
          <FormattedMessage id="terms_of_use" />
        </LocalizedLink>
      </PrivacyPolicy>
    </Bottom>
  </Container>
);

export default Footer;
