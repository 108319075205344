const breakpoints = {
  xs: "414px",
  sm: "576px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
  ll: "1320px",
  hg: "1440px",
  hh: "1560px",
};

export default breakpoints;
