import React from "react";
import styled, { css } from "styled-components";
import { injectIntl } from "react-intl";
import LocalizedLink from "./LocalizedLink";
import colors from "../../styles/colors";
import { fontFG16m } from "../../styles/fonts";
import { getLocaleByKey } from "../../utilities/i18n";
import { BUSINESS_SIGNUP_LINK } from "../../config";
import { appendUtmContentToUrl } from "../utilities/utmUtils";

const getButtonTypeStyle = (type) => {
  switch (type) {
    case "outline":
      return css`
        background: transparent;
        color: ${colors.green};
      `;
    default:
      return css``;
  }
};

// TODO: Update styling
const buttonStyle = css`
  -webkit-appearance: none;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
  display: inline-block;

  padding: 16px 40px;
  border: 1px solid ${colors.green};
  border-radius: 3px;
  color: ${colors.white};
  background: ${colors.green};
  ${fontFG16m};
  line-height: 18px;
  text-align: center;

  ${(props) => getButtonTypeStyle(props.type)};
`;

const A = styled.a`
  ${buttonStyle};
  // disable color change
  :hover {
    color: ${colors.white};
    background: ${colors.green};
    border: 1px solid ${colors.green};
  }
`;

const Link = styled(LocalizedLink)`
  ${buttonStyle};
  // disable color change
  :hover {
    color: ${colors.white};
    background: ${colors.green};
    border: 1px solid ${colors.green};
  }
`;

const StyledButton = styled.button`
  ${buttonStyle};
`;

const Button = ({
  to,
  intl: { locale: localeKey },
  href,
  utmContent,
  ...props
}) => {
  if (to && !to.startsWith("http")) {
    return <Link to={to} {...props} />;
  }

  // Redirect to localized sign up page
  if (href && href === BUSINESS_SIGNUP_LINK) {
    const locale = getLocaleByKey(localeKey);
    const isThai = locale.key === "th";
    const isIndo = locale.key === "id";
    if (isThai || isIndo) {
      const lang = isThai ? "th" : "id";
      href = `${BUSINESS_SIGNUP_LINK}?lng=${lang}`;
    } else {
      href = `${BUSINESS_SIGNUP_LINK}?lng=en`;
    }

    href = appendUtmContentToUrl(href, utmContent);

    return <A href={href} {...props} />;
  }

  // matches business signup link with pre-existing query params set
  const urlPattern = /dashboard\.workmate\.asia\/signup/;
  if (urlPattern.test(href)) {
    href = appendUtmContentToUrl(href, utmContent);
    return <A href={href} {...props} />;
  }

  if (href) {
    return <A href={href} {...props} />;
  }

  return <StyledButton {...props} />;
};

export default injectIntl(Button);
