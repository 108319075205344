import _ from "lodash";
import { utmTypes } from "../../constants";

export const getUtmContentFromParams = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  const utmContent = {};

  // extract utm content from search params
  for (let param of searchParams) {
    const [utmKey, utmValue] = param;
    if (Object.values(utmTypes).includes(utmKey)) {
      utmContent[utmKey] = utmValue;
    }
  }

  return _.isEmpty(utmContent) ? undefined : utmContent;
};

export const appendUtmContentToUrl = (url, utmContent = {}) => {
  if (_.isEmpty(utmContent)) return url;

  let urlWithUtmContent = new URL(url);

  for (const [key, value] of Object.entries(utmContent)) {
    urlWithUtmContent.searchParams.append(key, value);
  }

  return urlWithUtmContent.toString();
};
