import React from "react";
import { Link } from "gatsby";
import { getLocaleByKey, getUrlForLocale } from "../../utilities/i18n";
import { injectIntl } from "react-intl";

/* Localizes internal links. Should not be used for external links */
const LocalizedLink = ({ to, intl: { locale: localeKey }, ...props }) => {
  const locale = getLocaleByKey(localeKey);
  return <Link to={getUrlForLocale(to, locale)} {...props} />;
};

export default injectIntl(LocalizedLink);
